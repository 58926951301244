/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import pinia from "./plugins/pinia";
import router from "./router";
import { auth } from "@/services/firebase";
import { useSessionStore } from "@/store/session";

Vue.config.productionTip = false;

// start session management before the app
const session = useSessionStore();

auth.onAuthStateChanged(async (user) => {
    if (user) {
        session.logger(
            "M: user authenticated [S: " +
                session.initState +
                "]" +
                "[R: " +
                router.currentRoute.name +
                "]"
        );
        await session.initialize();
        if (!session.app) {
            session.logger(
                "M: creating app" +
                    "[S: " +
                    session.initState +
                    "]" +
                    "[R: " +
                    router.currentRoute.name +
                    "]"
            );
            session.app = new Vue({
                pinia,
                router,
                vuetify,
                render: (h) => h(App),
                created() {
                    session.logger(
                        "M: app created" +
                            "[S: " +
                            session.initState +
                            "]" +
                            "[R: " +
                            router.currentRoute.name +
                            "]"
                    );
                },
            }).$mount("#app");
        } else {
            session.logger(
                "M: going home" +
                    "[S: " +
                    session.initState +
                    "]" +
                    "[R: " +
                    router.currentRoute.name +
                    "]"
            );
            router.push({ name: "Home" });
        }
    } else {
        session.logger("M: user anonymous");
        if (!session.app) {
            session.logger(
                "M: creating app for anonymous user.." +
                    "[S: " +
                    session.initState +
                    "]" +
                    "[R: " +
                    router.currentRoute.name +
                    "]"
            );
            session.app = new Vue({
                pinia,
                router,
                vuetify,
                render: (h) => h(App),
                created() {
                    session.logger(
                        "M: app for anonymous user is created" +
                            "[S: " +
                            session.initState +
                            "]" +
                            "[R: " +
                            router.currentRoute.name +
                            "]"
                    );
                },
            }).$mount("#app");
        } else {
            session.logger("M: anonymous user, no app");
        }
    }
});
