<template>
	<v-container fluid>
		<v-toolbar elevation="0" class="transparent">
			<v-toolbar-title> Home </v-toolbar-title>
		</v-toolbar>
		<v-divider />
	</v-container>
</template>

<script>
	export default {
		name: "Home",
		data() {
			return {};
		},
	};
</script>
