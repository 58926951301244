/* eslint-disable */
import navigation from "@/store/navigation.json";
import { defineStore } from "pinia";
import { auth, functions } from "@/services/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

auth.onAuthStateChanged(async (user) => {
    useSessionStore().authenticated = !!user;
});

export const useSessionStore = defineStore({
    id: "session",
    state: () => ({
        app: null,
        initState: "",
        authenticated: false,
        authorized: false,
        authorizedResources: [],
        navigation: navigation.navItems,
        notification: {
            isVisible: false,
            success: false,
            error: "",
        },
        log: [],
    }),
    actions: {
        logger(msg) {
            if (process.env.VUE_APP_LOGGER) {
                let entry = {
                    timestamp: new Date(),
                    msg: msg,
                };
                this.log.push(entry);
                //console.log(msg);
            }
        },
        async initialize() {
            this.initState = "initializing";
            this.logger("S: " + this.initState);
            const data = {
                appId: process.env.VUE_APP_APP_NAME,
                domain: window.location.hostname,
            };
            const call = httpsCallable(functions, "authorize");
            return call(data)
                .then((result) => {
                    if (result && result.data) {
                        this.authorizedResources =
                            result.data.authorizedResources;
                        this.authorized =
                            this.authorizedResources &&
                            this.authorizedResources.length > 0;
                        this.initState = "initialized";
                        this.logger(
                            "S: " +
                                this.initState +
                                " " +
                                (this.authorized
                                    ? "authorized"
                                    : "access denied")
                        );
                    }
                })
                .catch((e) => {
                    this.showError(e);
                    this.initState = "error";
                    this.logger("S: " + e);
                });
        },
        showSuccess() {
            this.notification = {
                isVisible: true,
                success: true,
                error: "",
            };
            var timeout = 2500,
                that = this;
            window.setTimeout(function () {
                that.hideNotification();
            }, timeout);
        },
        showError(_error) {
            this.notification = {
                isVisible: true,
                success: false,
                error: _error,
            };
            var timeout = 10000,
                that = this;
            window.setTimeout(function () {
                that.hideNotification();
            }, timeout);
        },
        hideNotification() {
            this.notification = {
                isVisible: false,
                success: false,
                error: "",
            };
        },
        login(u, p) {
            return signInWithEmailAndPassword(auth, u, p);
        },
        logout() {
            this.authorized = false;
            window.setTimeout(function () {
                // TBD: clear all stores in stead of reloading the window
                window.location.reload();
                signOut(auth);
            }, 2000);
        },
    },
    getters: {
        navigationItems: (state) => {
            return state.navigation.filter((navItem) => {
                return (
                    state.authorizedResources &&
                    state.authorizedResources.indexOf(navItem.routeName) >= 0
                );
            });
        },
        lastLogEntry: (state) => {
            let logEntry = state.log.length;
            if (logEntry) return state.log[logEntry - 1].msg;
        },
    },
});
