import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import {
    getFunctions /*, connectFunctionsEmulator */,
} from "firebase/functions";

// init app
const firebaseConfig = {
    apiKey: "AIzaSyBhaI1HfJHgc357NU-hCuNSg7Zeweq_vEo",
    authDomain: "continuans.firebaseapp.com",
    databaseURL: "https://continuans.firebaseio.com",
    projectId: "continuans",
    storageBucket: "continuans.appspot.com",
    messagingSenderId: "567867533257",
    appId: "1:567867533257:web:3ca63c2e41a2f0a039ea18",
};
const app = initializeApp(firebaseConfig);

// add auth to app
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "europe-west1");

export { auth, db, storage, functions };
