/* eslint-disable */
<template>
	<v-card
		height="400px"
		width="400px"
		min-height="50vh"
		max-height="80vh"
		max-width="90vw"
		class="cntnr d-flex flex-column justify-space-between"
	>
		<v-sheet>
			<v-card-title>{{ tenantName }} {{ appName }}</v-card-title>
			<v-card-subtitle
				>Meld je aan met email en wachtwoord</v-card-subtitle
			>
		</v-sheet>
		<v-card-text>
			<v-form @keyup.native.enter="submitLogin">
				<v-text-field
					v-model="userName"
					type="text"
					placeholder="E-mail"
					clearable
					prepend-icon="mdi-account-circle"
					single-line
					hide-details
				/>
				<v-text-field
					v-model="userPwd"
					:type="showPassword ? 'text' : 'password'"
					placeholder="Wachtwoord"
					single-line
					hide-details
					prepend-icon="mdi-lock"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="showPassword = !showPassword"
				/>
				<p v-if="error" class="red--text text-center my-4">
					{{ errMsg }}
				</p>
			</v-form>
		</v-card-text>
		<v-sheet>
			<v-card-actions>
				<v-btn
					block
					:loading="loading"
					color="primary"
					@click.stop="submitLogin"
				>
					Aanmelden
				</v-btn>
			</v-card-actions>
		</v-sheet>
	</v-card>
</template>

<script>
	import { useSessionStore } from "@/store/session";

	export default {
		name: "Login",
		data: () => ({
			userName: "",
			userPwd: "",
			showPassword: false,
			error: false,
			errMsg: "",
			loading: false,
			appName: process.env.VUE_APP_APP_NAME,
			tenantName: process.env.VUE_APP_TENANT_NAME,
		}),
		setup() {
			const session = useSessionStore();
			return { session };
		},
		methods: {
			submitLogin() {
				this.loading = true;
				this.session
					.login(this.userName, this.userPwd)
					.catch(() => {
						this.error = true;
						this.errMsg = "Dit is een ongeldige login.";
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>

