/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import { useSessionStore } from "@/store/session";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/users",
        name: "Users",
        component: lazyLoad("Users"),
    },
    {
        path: "/users/user/:id",
        name: "User",
        component: lazyLoad("user/User"),
    },
    {
        path: "/webapps",
        name: "WebApps",
        component: lazyLoad("WebApps"),
    },
    {
        path: "/webapps/webapp/:id",
        name: "WebApp",
        component: lazyLoad("webapp/WebApp"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// N A V I G A T I O N   G U A R D
router.beforeEach((to, from, next) => {
    const session = useSessionStore();
    if (
        session.authorized &&
        session.authorizedResources.indexOf(to.name) >= 0
    ) {
        session.logger(
            "R: " + to.name + ": approved [S: " + session.initState + "]"
        );
        next();
    } else {
        session.logger(
            "R: " + to.name + ": rejected [S: " + session.initState + "]"
        );
        next(false);
    }
});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || process.env.VUE_APP_APP_NAME;
    });
});

function lazyLoad(view) {
    return () => {
        return import(`@/views/${view}.vue`);
    };
}

const scrollBehavior = function (to, from, savedPosition) {
    let scrollTo = 0;
    if (to.hash) {
        scrollTo = to.hash;
    } else if (savedPosition) {
        scrollTo = savedPosition.y;
    }
    return setTimeout(function () {
        goTo(scrollTo);
    }, 1000);
};

export default router;
