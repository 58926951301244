/* eslint disable */
<template>
	<v-app id="app">
		<!-- NAVDRAWER  -->
		<v-navigation-drawer
			app
			v-show="session.authorized"
			v-model="drawer"
			class="grey lighten-4"
		>
			<v-toolbar flat class="transparent">
				<v-toolbar-title>
					<v-img
						src="./assets/logo.png"
						height="36"
						contain
						position="left center"
				/></v-toolbar-title>
			</v-toolbar>
			<smart-nav-bar vertical :navItems="session.navigationItems" />
		</v-navigation-drawer>
		<!-- APP BAR -->
		<v-app-bar app v-show="session.authorized" flat dense>
			<v-app-bar-nav-icon
				@click.stop="drawer = !drawer"
			></v-app-bar-nav-icon>
			<v-spacer />
			<v-icon @click.stop="session.logout()">mdi-exit-to-app</v-icon>
		</v-app-bar>
		<!-- MAIN -->
		<v-main v-scroll="onScroll" class="grey lighten-4">
			<div v-if="!session.authenticated">
				<transition name="fade" mode="in-out">
					<Login></Login>
				</transition>
			</div>
			<div
				v-show="
					(session.authenticated && !session.authorized) ||
					(!session.authenticated && session.authorized)
				"
			>
				<transition name="fade" mode="out-in">
					<v-progress-circular
						class="cntnr"
						indeterminate
						color="primary"
						size="64"
					></v-progress-circular>
				</transition>
			</div>
			<div v-if="session.authenticated && session.authorized">
				<transition name="fade" mode="out-in">
					<keep-alive>
						<router-view> </router-view>
					</keep-alive>
				</transition>
			</div>
		</v-main>

		<!-- FOOTER -->

		<v-footer app bottom outlined class="grey lighten-3">
			<!-- LOG -->
			<span class="text-caption">{{ this.session.lastLogEntry }}</span>
			<v-divider vertical />
			<v-spacer />
			<!-- NOTIFICATIONS -->
			<v-bottom-sheet
				:value="session.notification.isVisible"
				hide-overlay
				width="auto"
			>
				<v-btn
					v-if="session.notification.success"
					icon
					large
					fab
					@click="session.notification.isVisible = false"
					><v-icon color="success">mdi-thumb-up</v-icon></v-btn
				>
				<v-sheet
					v-if="session.notification.error"
					class="mx-auto"
					max-height="150px"
					dark
					><v-container>
						<v-row align="center">
							<v-col cols="auto">
								{{ session.notification.error }}
							</v-col>
							<v-col cols="1">
								<v-btn
									icon
									@click="
										session.notification.isVisible = false
									"
									><v-icon>mdi-close-circle-outline</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-sheet>
			</v-bottom-sheet>
			<!-- SCROLL TOP -->
			<v-btn
				v-show="this.scroller"
				fab
				large
				color="info"
				icon
				v-on:click.stop="$vuetify.goTo(0)"
			>
				<v-icon>mdi-arrow-up-thin-circle-outline</v-icon>
			</v-btn>
		</v-footer>
	</v-app>
</template>

<script>
	import SmartNavBar from "./components/SmartNavBar.vue";
	import { useSessionStore } from "@/store/session";
	import Login from "./components/Login";

	export default {
		name: "App",
		components: { SmartNavBar, Login },
		setup() {
			const session = useSessionStore();
			return { session };
		},
		data: () => ({
			drawer: null,
			scroller: false,
			appName: process.env.VUE_APP_APP_NAME,
		}),
		methods: {
			onScroll() {
				this.scroller = window.pageYOffset > 256;
			},
			logout() {},
		},
	};
</script>

<style>
	/* POSITIONING */
	.cntnr {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.kmp {
		position: fixed;
		left: 50%;
		transform: translate(-50%, 0%);
	}

	/*** SCROLLBAR ***/
	/* width */
	::-webkit-scrollbar {
		width: 8px;
	}
	/* Track */
	::-webkit-scrollbar-track {
		background: #eceff1; /* blue-grey lighten-5  */
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #b0bec5; /* blue-blue-grey lighten-5  */
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #78909c; /* blue-grey lighten-1 */
	}

	/*** TRANSITIONS ***/
	.fade-enter {
		opacity: 0;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease-out;
	}
	.fade-leave-to {
		opacity: 0;
	}
	.slide-fade-enter {
		transform: translateX(10px);
		opacity: 0;
	}
	.slide-fade-enter-active,
	.slide-fade-leave-active {
		transition: all 0.25s ease;
	}
	.slide-fade-leave-to {
		transform: translateX(-10px);
		opacity: 0;
	}
</style>
